import Header from "./components/Header";
import Varilux from "./components/Varilux";

const link = "https://api.whatsapp.com/send?phone=552733387520&text=Ol%C3%A1%2C%20peguei%20seu%20n%C3%BAmero%20no%20site%20da%20Otica%20Gama.%20Poderia%20me%20ajudar%3F"

export default function App() {
  return (
    <>
     <Header/> 
     <Varilux/>
    </>
  );
}
