import styled from "styled-components";
import logo from "../images/logoVarilux.png";
import logoWhatsapp from "../images/logoWhatsapp.png";
import dobro from "../images/dobro.png";
import familia from "../images/familia.png";
import primeiro from "../images/primeiro.png";

export default function Varilux() {
    const phoneNumber = "552733387520";
    const message = "Olá, vim pelo seu site e gostaria de saber mais sobre os seus serviços.";

    const handleClick = () => {
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
    };
    
    return (
        <Box>
            <Logo src={logo} />
            <Options>
                <Option src={dobro} />
                <Option src={familia} />
                <Option src={primeiro} />
                <WhatsappLogo onClick={handleClick} src={logoWhatsapp} />
            </Options>
        </Box>
    )
}

const WhatsappLogo = styled.img`
    position: fixed;
    right: 3%;
    bottom: 2%;
    width: 60px;
`

const Box = styled.div`
    padding-top: 10%;
    width: 100%;
    padding-bottom: 5%;
    background-color: #010137;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const Options = styled.div`
    padding-top: 20px;
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const Option = styled.img`
    width: 100%;
    display: flex;
    padding-bottom: 10%;

    border-radius: 6%;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
`

const Logo = styled.img`
    padding-top: 10%;
    width: 50%;
`