// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Reset de Estilos Padrão do Navegador */

  *{
    box-sizing: border-box;
  }
  body,
  h1, h2, h3, h4, h5, h6,
  p,
  ol, ul,
  figure,
  blockquote,
  dl, dd, dt {
    margin: 0;
    padding: 0;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  /* Adicione outros estilos de reset conforme necessário */

  /* Adicione estilos gerais conforme necessário */
`;

export default GlobalStyles;
