import styled from "styled-components";
import logo from "../images/logoOtica.png";

export default function Header() {
    return (
        <Box>
            <Logo src={logo} />
        </Box>
    )
}

const Box = styled.div`
    position: fixed;
    width: 100%;
    height: auto;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.1);
`

const Logo = styled.img`
    width: 30%;
    margin: 0.5%;
`